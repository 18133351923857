import React from "react";

import logoImg from "../../assets/images/logo-dark.png";
import { Link } from "react-router-dom";

function TopBar() {
  return (
    <>
      <header className="main-head">
        <div className="topbar-container">
          <Link to="/mi-espacio/agenda">
            <img src={logoImg} className="topbar-logo" alt="Mindly Logo" />
          </Link>
        </div>
      </header>
    </>
  );
}

export default TopBar;
