import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Badge } from "reactstrap";
// import lottie from "lottie-web";
import psicologoImg from "../../assets/images/psicologo-min.png";

// import images
// import doctors from "../../assets/images/animations/psicologos.json";

const Section = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   lottie.loadAnimation({
  //     container: document.getElementById("psicologos-animation"),
  //     animationData: doctors,
  //   });
  // }, []);

  return (
    <React.Fragment>
      <section className="bg-half-170 d-table w-100 home-wrapper overflow-hidden">
        <Container>
          <Row className="mt-3 align-items-center">
            <Col lg={6} md={6}>
              <div className="title-heading">
                {windowWidth > 768 && (
                  <span className="badge rounded-pill bg-soft-primary">
                    <b>Online</b>
                  </span>
                )}
                <h1 className="heading my-3 main-title">
                  Tu Paz Mental,
                  <br /> sin salir de casa 😌
                  {/* <span className="text-primary">Psicólogos online</span> */}
                </h1>

                <div className="sub-title">
                  <li className="para-desc text-muted mb-0 section-li">
                    <i className="uil uil-check-circle align-middle text-primary me-2"></i>
                    <span className="align-middle">
                      Habla con un Psicólogo Online
                    </span>
                  </li>
                  <li className="para-desc text-muted mb-0 section-li">
                    <i className="uil uil-check-circle align-middle text-primary me-2"></i>
                    <span className="align-middle">
                      Sesiones por Videollamada
                    </span>
                  </li>
                  <li className="para-desc text-muted mb-0 section-li">
                    <i className="uil uil-check-circle align-middle text-primary me-2"></i>
                    <span className="align-middle">
                      En un entorno Seguro y Privado
                    </span>
                  </li>
                </div>

                <div className="mt-4">
                  <Link to="/register" className="btn  btn-primary me-2  ">
                    Comienza aquí
                  </Link>{" "}
                  <Badge className="bg-soft-primary mt-2">
                    <i className="uil uil-check-circle align-middle me-1"></i>{" "}
                    <span className="align-middle" style={{ fontSize: 13 }}>
                      1ra Cita 30% OFF
                    </span>
                  </Badge>
                </div>
              </div>
            </Col>

            <Col lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="ms-lg-4">
                {/* <div id="psicologos-animation" /> */}
                <img
                  src={psicologoImg}
                  alt="psicologos"
                  className="img-fluid mx-auto d-block"
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Section;
