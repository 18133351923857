import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

function TimerComponent() {
  const history = useHistory();

  useEffect(() => {
    let timeinterval; // Define timeinterval here

    const getTimeRemaining = (endtime) => {
      const total = Date.parse(endtime) - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));
      return {
        total,
        days,
        hours,
        minutes,
        seconds,
      };
    };

    const initializeClock = (id, endtime) => {
      const clock = document.getElementById(id);
      const daysSpan = clock.querySelector(".days");
      const hoursSpan = clock.querySelector(".hours");
      const minutesSpan = clock.querySelector(".minutes");
      const secondsSpan = clock.querySelector(".seconds");

      const updateClock = () => {
        const t = getTimeRemaining(endtime);

        daysSpan.innerHTML = t.days;
        hoursSpan.innerHTML = ("0" + t.hours).slice(-2);
        minutesSpan.innerHTML = ("0" + t.minutes).slice(-2);
        secondsSpan.innerHTML = ("0" + t.seconds).slice(-2);

        if (t.total <= 0) {
          hoursSpan.innerHTML = "00";
          minutesSpan.innerHTML = "00";
          secondsSpan.innerHTML = "00";
          clearInterval(timeinterval);
        }
      };

      updateClock();
      timeinterval = setInterval(updateClock, 1000); // Assign the interval to timeinterval
    };

    const deadline = new Date(Date.parse(new Date()) + 5 * 24 * 35 * 46 * 1000);
    initializeClock("clockdiv", deadline);
  }, []);

  const handlePromoClick = () => {
    history.push("/mi-espacio/especialistas");
  };
  return (
    <div className="footer_timer" onClick={handlePromoClick}>
      <div className="container">
        <div className="col-md-12">
          <div id="clockdiv">
            <h3
              className="time_messege"
              style={{ display: "inline-block", color: "#fff" }}
            >
              <b>
                👉 Tu primera cita con{" "}
                <span
                  className="offercopy font_Inter_Black"
                  id="copy_text"
                  data-toggle="tooltip"
                >
                  30% OFF
                </span>{" "}
                Utiliza el código "BIENESTAR30"
              </b>
            </h3>
            <div>
              <div className="smalltext text-capitalize">Días</div>
              <span className="days"></span>
            </div>
            <span className="dot_time">:</span>
            <div>
              <div className="smalltext text-capitalize">Horas</div>
              <span className="hours"></span>
            </div>
            <span className="dot_time">:</span>
            <div>
              <div className="smalltext text-capitalize">Minutos</div>
              <span className="minutes"></span>
            </div>
            <span className="dot_time">:</span>
            <div>
              <div className="smalltext text-capitalize">Segundos</div>
              <span className="seconds"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimerComponent;
